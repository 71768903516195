import {openLightbox} from "./main.js";

const meblostyl = [
    require("./vendor/img/meblostyl/nowak_meble_lawa_014.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_stol_002.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_trzebnica_lawa_007.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_trzebnica_lawa_008.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_trzebnica_lawa_009.jpg"),
    require("./vendor/img/meblostyl/100_1076x.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_trzebnica_lawa_006.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_stol_003.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_stol_004.jpg"),
    require("./vendor/img/meblostyl/nowak_-meble_lawa_016.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_lawa_015.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_trzebnica_lawa_011.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_trzebnica_lawa_010.jpg"),
    require("./vendor/img/meblostyl/nowak_meble_trzebnica_lawa_013.jpg"),
]

const polki = [
    require("./vendor/img/polki/nowak_meble_trzebnica_polka_001.jpg"),
    require("./vendor/img/polki/nowak_-meble_trzebnica_polka_002.jpg"),
    require("./vendor/img/polki/nowak_-meble_trzebnica_polka_003.jpg"),
    require("./vendor/img/polki/nowak_-meble_trzebnica_polka_004.jpg"),
    require("./vendor/img/polki/nowak_-meble_trzebnica_polka_005.jpg"),
]
const szafki = [
    require("./vendor/img/szafki-rtv/nowak_meble_trzebnica_rtv-004.jpg"),
    require("./vendor/img/szafki-rtv/nowak_meble_trzebnica_rtv_001.jpg"),
    require("./vendor/img/szafki-rtv/nowak_meble_trzebnica_rtv_002.jpg"),
    require("./vendor/img/szafki-rtv/nowak_meble_trzebnica_rtv_003.jpg"),
    require("./vendor/img/szafki-rtv/nowak_meble_trzebnica_rtv_005.jpg"),
]

const szafy = [
    require("./vendor/img/szafy/nowak_meble_szafa_002.jpg"),
    require("./vendor/img/szafy/nowak_meble_szafa_003.jpg"),
    require("./vendor/img/szafy/nowak_meble_szafa_004.jpg"),
    require("./vendor/img/szafy/nowak_meble_trzebnica_szafa_001.jpg")
]


const witryny = [
    require("./vendor/img/witryny/nowak_meble_trzebnica_witryna_001.jpg"),
    require("./vendor/img/witryny/nowak_meble_trzebnica_witryna_002.jpg"),
    require("./vendor/img/witryny/nowak_meble_witryna_003.jpg"),
]

const furnitureList = [
        {
            path: require("./vendor/img/belki/nowak_meble_trzebnica_belka_001.jpg"),
            description: "Belka",
        },
        {
            path: require("./vendor/img/belki/nowak_meble_trzebnica_belka_002.jpg"),
            description: "Belka 2",
        },
        {
            path: require("./vendor/img/komody/nowak_meble_trzebnica_komoda_001.jpg"),
            description: "Komoda",
        },
        {
            path: require("./vendor/img/komody/nowak_trzebnica_meble_komoda_002.jpg"),
            description: "Komoda 2",
        },
        {
            path: require("./vendor/img/komody/nowak_meble_komoda_003.jpg"),
            description: "Komoda 3",
        },
        {
            path: require("./vendor/img/komody/nowak_meble_komoda_004.jpg"),
            description: "Komoda 4",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_krzeslo_006.jpg"),
            description: "Krzesło",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_krzeslo_007.jpg"),
            description: "Krzesło 2",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_krzeslo_011.jpg"),
            description: "Krzesło 3",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_krzeslo_012.jpg"),
            description: "Krzeslo 4",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_krzeslo_012_1328370588.jpg"),
            description: "Krzeslo 5",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_krzeslo_013.jpg"),
            description: "Krzeslo 6",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_trzebnica_krzeslo_001.jpg"),
            description: "Krzeslo 7",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_trzebnica_krzeslo_002.jpg"),
            description: "Krzeslo 8",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_trzebnica_krzeslo_003.jpg"),
            description: "Krzeslo 9",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_trzebnica_krzeslo_004.jpg"),
            description: "Krzeslo 10"
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_trzebnica_krzeslo_009.jpg"),
            description: "Krzeslo 11",
        },
        {
            path: require("./vendor/img/krzesla/nowak_meble_trzebnica_krzeslo_010.jpg"),
            description: "Krzeslo 12",
        },
        ...meblostyl.map((m, i) => {
            return {
                path: m,
                description: `Meblostyl ${i + 1}`,
            }
        }),
        ...polki.map((polka, i) => {
            return {
                path: polka,
                description: `Półka ${i + 1}`,
            }
        }),
        ...szafki.map((szafka, i) => {
            return {
                path: szafka,
                description: `Szafka ${i + 1}`,
            }
        }),
        ...szafy.map((szafa, i) => {
            return {
                path: szafa,
                description: `Szafa ${i + 1}`,
            }
        }),
        ...witryny.map((w, i) => {
            return {
                path: w,
                description: `Witryna ${i + 1}`,
            }
        })
    ]
;

function renderList() {
    const list = document.querySelector('#gallery')
    furnitureList.forEach((item) => {
        const listItem = document.createElement('div');
        listItem.className = 'gallery-item';

        let img = document.createElement('img',);
        img.src = item.path;
        img.alt = item.description;
        img.onclick = () => {
            openLightbox(item.path, item.description)
        }
        const descriptionItem = document.createElement('div');
        descriptionItem.className = "caption";
        descriptionItem.textContent = item.description;

        listItem.appendChild(img);
        listItem.appendChild(descriptionItem);
        list.appendChild(listItem)
    })
}

renderList();

