export const openLightbox = (imageSrc, description) => {
    const lightbox = document.getElementById('lightbox');
    const lightboxImage = lightbox.querySelector('.lightbox-image');
    const lightboxDescription = lightbox.querySelector('.lightbox-description');

    lightboxImage.src = imageSrc;
    lightboxDescription.textContent = description;

    lightbox.classList.add('active');
}
